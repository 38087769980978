<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="app-video-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <p class="youtube-p">Question 2 of 6</p>
                    <div class="app-intro-video-content">
                        <h1 class="headline">Did you take <span>care of a child</span> under the age of 18 in <span>2020</span> and/or <span>2021</span>?
                        </h1>
                        <div class="row justify-content-center">
                            <div class="col">
                                <a (click)="this.CaredForChildCheck('Yes');">
                                    <div class="features-card style-two">
                                        <h1 style="font-weight: bold;">Yes</h1>
                                        <div class="icon">
                                            <i class="ri-thumb-up-fill"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div class="col">
                                <a (click)="this.CaredForChildCheck('No');">
                                    <div class="features-card style-two">
                                        <h1 style="font-weight: bold;">No</h1>
                                        <div class="icon">
                                            <i class="ri-thumb-down-fill"></i>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h5 class="haus-answer-h5" *ngIf="this.ghl.CareForChild2020to2021">Cared for Your Child in 2020 to 2021:
                            <span style="color: #0F50A1;">{{ this.ghl.CareForChild2020to2021 }}</span>
                        </h5>
                        <a class="haus-btn" *ngIf="this.ghl.CareForChild2020to2021" routerLink="/did-you-lost-ability-to-work-2020-to-2021">
                            <h1>NEXT</h1>
                        </a>
                        <h5 class="haus-h5" *ngIf="this.ghl.CareForChild2020to2021" routerLink="/">Go Back</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
    
    <div class="video-popup" *ngIf="isOpen">
        <div class="popup-inner">
            <iframe src="../../../../assets/img/video/what-is-the-SETC.mp4" title="Nvestor Funding Steve Afra" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <button (click)="closePopup()" type="button" class="close-btn">
                <i class="ri-close-line"></i>
            </button>
        </div>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>