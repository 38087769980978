<app-navbar-style-two></app-navbar-style-two>
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>How It Works</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>How It Works</li>
            </ul>
        </div>
    </div>
    <div class="divider"></div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
    <div class="banner-shape1"><img src="assets/img/shape/shape9.png" alt="image"></div>
</div>

<div class="how-it-works-area ptb-100">
    <div class="container">
        <div class="how-it-works-content">
            <div class="number">1</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 1</h3>
                        <span>Create an Account</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco consectetur adipiscing.</p>
                        <img src="assets/img/how-it-works/create-account.jpg" alt="create-account">
                    </div>
                </div>
            </div>
        </div>
        <div class="how-it-works-content">
            <div class="number">2</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 2</h3>
                        <span>Create an Account</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco consectetur adipiscing.</p>
                        <img src="assets/img/how-it-works/create-account.jpg" alt="create-account">
                    </div>
                </div>
            </div>
        </div>
        <div class="how-it-works-content">
            <div class="number">3</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 3</h3>
                        <span>Create an Account</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco consectetur adipiscing.</p>
                        <img src="assets/img/how-it-works/create-account.jpg" alt="create-account">
                    </div>
                </div>
            </div>
        </div>
        <div class="how-it-works-content">
            <div class="number">4</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 4</h3>
                        <span>Create an Account</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco consectetur adipiscing.</p>
                        <img src="assets/img/how-it-works/create-account.jpg" alt="create-account">
                    </div>
                </div>
            </div>
        </div>
        <div class="how-it-works-content">
            <div class="number">5</div>
            <div class="row m-0">
                <div class="col-lg-3 col-md-12 p-0">
                    <div class="box">
                        <h3>Step 5</h3>
                        <span>Create an Account</span>
                    </div>
                </div>
                <div class="col-lg-9 col-md-12 p-0">
                    <div class="content">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco consectetur adipiscing.</p>
                        <img src="assets/img/how-it-works/create-account.jpg" alt="create-account">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>