<div class="footer-wrap-area pt-100">
    <div class="container">
        <div class="row">

        </div>
        <div class="copyright-area">
            <p>© The Tax Credit Expert 2024. The Tax Credit Expert provides educational information and resources,
                including our top recommendations for tax credit processors and providers. If you choose to work with
                somebody we recommend, we may receive a referral fee, though not one that will ever impact what the
                client pays.</p>
        </div>
    </div>
</div>