<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="app-video-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <p class="youtube-p">Question 6 of 7</p>
                    <div class="app-intro-video-content">
                        <h1 class="headline">What's your <span>email address</span>?</h1>
                        <input type="text" 
                            [(ngModel)]="this.ghl.Email"
                            class="form-control haus-form-control" 
                            placeholder="Email Address"
                        />
                        <!-- <form [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col mb-3">
                                    <input type="text" [(ngModel)]="this.ghl.Email" formControlName="email"
                                        class="form-control haus-form-control" placeholder="Email Address"
                                        [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                            </div>
                        </form> -->
                    </div>
                    <div class="row">
                        <h5 class="haus-answer-h5" *ngIf="this.ghl.Email">Your Email Address: <span
                                style="color: #00BF63;">{{
                                this.ghl.Email }}</span></h5>
                        <p class="opt-in-p">By submitting your email address, you consent to being contacted by us
                            for additional information necessary to accurately determine your eligibility for tax
                            credits.
                        </p>
                        <a class="haus-btn" *ngIf="this.ghl.Email" (click)="onReset()"
                            routerLink="/your-name">
                            <h1>NEXT</h1>
                        </a>
                        <h5 class="haus-h5" *ngIf="this.ghl.Email" routerLink="/your-phone-number">Go Back</h5>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <p class="youtube-p">What is a Self-employed Tax Credit (SETC)?</p>
                    <div class="app-intro-video-box">
                        <img src="../../../../assets/img/video/tax-credit-advocates.png" alt="video-img">
                        <button class="video-btn popup-video" (click)="openPopup()"><i
                                class="ri-play-line"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="video-popup" *ngIf="isOpen">
        <div class="popup-inner">
            <iframe src="../../../../assets/img/video/what-is-the-SETC.mp4" title="Tax Credit" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            <button (click)="closePopup()" type="button" class="close-btn">
                <i class="ri-close-line"></i>
            </button>
        </div>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>