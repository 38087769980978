import { Component, OnInit } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-tax-credit-question-email-address',
  templateUrl: './tax-credit-question-email-address.component.html',
  styleUrls: ['./tax-credit-question-email-address.component.scss']
})
export class TaxCreditQuestionEmailAddressComponent {
  public form!: FormGroup;
  public submitted = false;
  public success: any;

  constructor(public ghl: GoHighLevelService, private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.form = this.formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
    });
}

// convenience getter for easy access to form fields
get f() { return this.form.controls; }

onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.form.invalid) {
        return;
    }
    this.success = 'Success';
    console.log('Email: ', this.ghl.Email);
}

onReset() {
    this.submitted = false;
    this.form.reset();
}

  // Video Popup
  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }
  closePopup(): void {
    this.isOpen = false;
  }
}
