import { Component, OnInit } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';
import { FormControl } from '@angular/forms';
import { Options, LabelType } from "@angular-slider/ngx-slider";

@Component({
  selector: 'app-tax-credit-question-approx-income2020to2021',
  templateUrl: './tax-credit-question-approx-income2020to2021.component.html',
  styleUrls: ['./tax-credit-question-approx-income2020to2021.component.scss']
})
export class TaxCreditQuestionApproxIncome2020to2021Component {
  public link: any;
  value: number = 3000;
  options: Options = {
    floor: 2500,
    ceil: 200000,
    translate: (value: number, label: LabelType): string => {
      this.ghl.ApproxIncome2020to2021 = value;
      switch (label) {
        case LabelType.Low:
          return "<b>Estimated Income: $</b> " + value;
        case LabelType.High:
          return "<b>Estimated Income: $</b> " + value;
        default:
          return value + "";
      }
    }
  };
  
  public Incomes: any = [
    {
      amount: '$5,000',
      value: '$5,000'
    },
    {
      amount: '$10,000',
      value: '$10,000'
    },
    {
      amount: '$20,000',
      value: '$20,000'
    },
    {
      amount: '$30,000',
      value: '$30,000'
    },
    {
      amount: '$40,000',
      value: '$40,000'
    },
    {
      amount: '$50,000',
      value: '$50,000'
    },
    {
      amount: '60,000',
      value: '$60,000'
    },
    {
      amount: '$70,000',
      value: '$70,000'
    },
    {
      amount: '$80,000',
      value: '$80,000'
    },
    {
      amount: '$90,000',
      value: '$90,000'
    },
    {
      amount: '$100,000',
      value: '$100,000'
    },
    {
      amount: '$150,000',
      value: '$150,000'
    },
    {
      amount: '$200,000',
      value: '$200,000'
    }
  ];
  constructor(public ghl: GoHighLevelService) { }

  ngOnInit() {}

  public IncomeCheck(value: any) {
    this.ghl.ApproxIncome2020to2021 = value;
    console.log('Estimated Income:', this.ghl.ApproxIncome2020to2021);
  }

  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }
  closePopup(): void {
    this.isOpen = false;
  }

}
