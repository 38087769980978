<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="app-video-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <p class="youtube-p">Question 5 of 7</p>
                    <div class="app-intro-video-content">
                        <h1 class="headline">What's the <span>best phone number</span> to call you on?</h1>
                        <input type="text" 
                               [(ngModel)]="this.ghl.Phone"
                               class="form-control haus-form-control" 
                               placeholder="Phone Number"
                               minlength="9" 
                               maxlength="10"
                               
                        />
                    </div>
                    <div class="row">
                        <h5 class="haus-answer-h5" *ngIf="this.ghl.Phone">Your Phone Number: <span style="color: #00BF63;">{{
                                this.ghl.Phone }}</span></h5>
                        <p class="opt-in-p">By providing your phone number, you authorize us to text you
                            with further details required to precisely assess your eligibility for tax credits.
                        </p>
                        <a class="haus-btn" *ngIf="this.ghl.Phone" (click)="onReset()"
                            routerLink="/your-email-address">
                            <h1>NEXT</h1>
                        </a>
                        <h5 class="haus-h5" *ngIf="this.ghl.Phone" routerLink="/whats-your-approx-income-2020-to-2021">Go
                            Back</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>