import { Component, OnInit } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';

@Component({
  selector: 'app-tax-credit-question-first-and-last-name',
  templateUrl: './tax-credit-question-first-and-last-name.component.html',
  styleUrls: ['./tax-credit-question-first-and-last-name.component.scss']
})
export class TaxCreditQuestionFirstAndLastNameComponent {
  public link:any;

  constructor(public ghl: GoHighLevelService) { }

  ngOnInit(): void {
  }

  // Video Popup
  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }
  closePopup(): void {
    this.isOpen = false;
  }
}
