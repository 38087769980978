import { Component, OnInit, } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-tax-credit-question-phone-number',
  templateUrl: './tax-credit-question-phone-number.component.html',
  styleUrls: ['./tax-credit-question-phone-number.component.scss']
})

export class TaxCreditQuestionPhoneNumberComponent {
  public Link: any;
  public form!: FormGroup;
  public submitted = false;
  public success: any;

  constructor(public ghl: GoHighLevelService, private formBuilder: FormBuilder) {
    this.form = this.formBuilder.group({
      phone: ['', [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]]
    });
  }

  ngOnInit(): void {
  }

  get f() { return this.form.controls; }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      return;
    }
    this.success = 'Success';
    console.log('Phone: ', this.ghl.Phone);
  }

  onReset() {
    this.submitted = false;
    this.form.reset();
  }

  isOpen = false;
  openPopup(): void {
    this.isOpen = true;
  }

  closePopup(): void {
    this.isOpen = false;
  }
}
