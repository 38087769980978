import { Component, OnInit } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';

@Component({
  selector: 'app-tax-credit-question-self-employed2020to2021',
  templateUrl: './tax-credit-question-self-employed2020to2021.component.html',
  styleUrls: ['./tax-credit-question-self-employed2020to2021.component.scss']
})
export class TaxCreditQuestionSelfEmployed2020to2021Component {
  public Check:any = [
    {
      check: 'Yes',
      value: 'Yes'
    },
    {
      check: 'No',
      value: 'No'
    }
  ];
  public Link:any;

  constructor(public ghl:GoHighLevelService) { }

  ngOnInit(): void {
  }

  public SelfEmployedCheck(value :any) {
    this.ghl.SelfEmployed2020to2021 = value;
    console.log('Self Employed 2020 to 2021:', this.ghl.SelfEmployed2020to2021);
    if (this.ghl.SelfEmployed2020to2021 == 'No') {
      this.Link = '/we-have-news-for-you';
    } else {
      this.Link = '/did-you-care-for-a-child-2020-to-2021'
    }
  }

    // Video Popup
    isOpen = false;
    openPopup(): void {
        this.isOpen = true;
    }
    closePopup(): void {
        this.isOpen = false;
    }

}
