import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { response } from 'express';

@Injectable({
  providedIn: 'root'
})
export class GoHighLevelService {
  private url = 'https://rest.gohighlevel.com/v1/contacts/';
  private customfieldurl = 'https://rest.gohighlevel.com/v1/custom-fields/';
  private apiKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJsb2NhdGlvbl9pZCI6ImZJU2NMNXNVcWJlZG4yOWNCZmFjIiwidmVyc2lvbiI6MSwiaWF0IjoxNzEyODA1MDU2NzYxLCJzdWIiOiJlb01acGVHZjVmem1mTnFpUXhoZyJ9.e3melbU7i4JWbUYnCSNRUGBCjjaKu2UGmCn2xOgBj8U';
  public FirstName: any;
  public LastName: any;
  public Phone: any;
  public Email: any;
  public SelfEmployed2020to2021: any;
  public CareForChild2020to2021: any;
  public AbilityToWork2020to2021: any;
  public ApproxIncome2020to2021: any;
  public PotentialEligibleTaxCredit: any;

  public IsParent: any;
  public NonParent: any;
  public IncomeIsOver: any;
  public IncomeIsNotOver: any;
  public DailyIncomeAmount: any;
  public DailyIncomeAmount67: any;
  public MaxRefundSole: any;
  public MaxRefundDependents: any;
  public MaxRefundDependentsTotal: any;
  public MaxRefundDependentsKidsPortion: any;
  public MaxRefundAmount: any;
  public data: any;
  public link:any;

  constructor(public http: HttpClient) { }

  //Income/260, Can't exceed $511.00
  DailyIncome() {
    this.DailyIncomeAmount = this.ApproxIncome2020to2021 / 260;
    if (this.DailyIncomeAmount >= 511) {
      this.DailyIncomeAmount = 511;
    } else {
      this.DailyIncomeAmount = this.DailyIncomeAmount;
    }
    console.log('Daily Income: ', this.DailyIncomeAmount);
  }

  //Daily Income * 0.67, Can't Exceed $200.00
  DailyIncome67() {
    this.DailyIncomeAmount67 = this.DailyIncomeAmount * 0.67;
    if (this.DailyIncomeAmount67 >= 200) {
      this.DailyIncomeAmount67 = 200;
    } else {
      this.DailyIncomeAmount67 = this.DailyIncomeAmount67;
    }
    console.log('67% of Daily Income: ', this.DailyIncomeAmount67);
  }

  //Daily Income * 0.67, Can't Exceed $200.00
  MaxAmount() {
    if (this.CareForChild2020to2021 == 'Yes') {
      this.IsParent = true;
      this.NonParent = false;

      this.MaxRefundDependentsKidsPortion = this.DailyIncomeAmount67 * 110;
      console.log('Maximum Amount with Dependents Kids Portion: ', this.MaxRefundDependentsKidsPortion);

      this.MaxRefundDependents = (this.DailyIncomeAmount * 20) + this.MaxRefundDependentsKidsPortion;
      console.log('Max Refund Amount Dependents:', this.MaxRefundDependents);

      this.MaxRefundAmount = this.MaxRefundDependents;
      console.log('Max Amount: ', this.MaxRefundAmount)
    } else {
      this.IsParent = false;
      this.NonParent = true;

      this.MaxRefundSole = this.DailyIncomeAmount * 20;
        console.log('Max Refund Sole: ', this.MaxRefundSole);
      this.MaxRefundAmount = this.MaxRefundSole;
        console.log('Max Amount: ', this.MaxRefundAmount)
    }
  }

  public RouterLinkCheck() {
    if(this.ApproxIncome2020to2021 <= 30000) {
      this.link = "/lets-schedule-a-call"
    } else {
      this.link = "/your-potential-tax-credit"
    }
  }

  public pushToGhl() {
    const headers = new HttpHeaders()
      .set('Authorization', `Bearer ${this.apiKey}`)
      .set('Content-Type', 'application/json');
    const data = {
      firstName: this.FirstName,
      lastName: this.LastName,
      phone: this.Phone,
      email: this.Email,
      "tags": [
        "thetaxcreditexpert.com"
      ],
      customField: {
        "QfyfpBse66ioRTBFctCa": this.SelfEmployed2020to2021,
        "ba4AAi9bJs94qLROtWIQ": this.CareForChild2020to2021,
        "jlkLwcFyIZa7dxPyqfIq": this.ApproxIncome2020to2021
      }
    };
    console.log('Data:', data);
    this.http.get(this.customfieldurl, { headers: headers }).subscribe(response => {
      console.log('Response', response);
    });
    this.http.post(this.url, data, { headers: headers }).subscribe(response => {
      console.log('Response:', response);
    });
  }

}
