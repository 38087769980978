import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutModernPageComponent } from './components/pages/about-modern-page/about-modern-page.component';
import { ContactPageComponent } from './components/pages/contact-page/contact-page.component';
import { HomeDemoEightComponent } from './components/pages/home-demo-eight/home-demo-eight.component';
import { NotFoundComponent } from './components/pages/not-found/not-found.component';
import { FixAndFlipProductPageComponent } from './components/pages/fix-and-flip-product-page/fix-and-flip-product-page.component';
import { LongTermRentalProductPageComponent } from './components/pages/long-term-rental-product-page/long-term-rental-product-page.component';
import { ShortTermBridgeProductPageComponent } from './components/pages/short-term-bridge-product-page/short-term-bridge-product-page.component';
import { GroundUpConstructionProductPageComponent } from './components/pages/ground-up-construction-product-page/ground-up-construction-product-page.component';
import { QuestionExitStrategyComponent } from './components/pages/question-exit-strategy/question-exit-strategy.component';
import { QuestionFlipExperienceComponent } from './components/pages/question-flip-experience/question-flip-experience.component';
import { QuestionRentalExperienceComponent } from './components/pages/question-rental-experience/question-rental-experience.component';
import { QuestionEstimatedFICOComponent } from './components/pages/question-estimated-fico/question-estimated-fico.component';
import { QuestionNameComponent } from './components/pages/question-name/question-name.component';
import { QuestionPhoneComponent } from './components/pages/question-phone/question-phone.component';
import { QuestionEmailComponent } from './components/pages/question-email/question-email.component';
import { QuestionPropertyAddressComponent } from './components/pages/question-property-address/question-property-address.component';
import { QuestionSubjectPropertyTypeComponent } from './components/pages/question-subject-property-type/question-subject-property-type.component';
import { QuestionSubjectPropertyCostComponent } from './components/pages/question-subject-property-cost/question-subject-property-cost.component';
import { QuestionWhatsNextComponent } from './components/pages/question-whats-next/question-whats-next.component';
import { DecemberTexasHardMoneyLoansComponent } from './components/pages/Blogs/december-texas-hard-money-loans/december-texas-hard-money-loans.component';
import { BlogMainComponent } from './components/pages/Blogs/blog-main/blog-main.component';
import { DecemberInvestingInFloridaComponent } from './components/pages/Blogs/december-investing-in-florida/december-investing-in-florida.component';
import { DecemberMinimalDownPaymentComponent } from './components/pages/Blogs/december-minimal-down-payment/december-minimal-down-payment.component';
import { DecemberGroundUpConstructionComponent } from './components/pages/Blogs/december-ground-up-construction/december-ground-up-construction.component';
import { JulyNextBigThingComponent } from './components/pages/Blogs/july-next-big-thing/july-next-big-thing.component';
import { JulyTopTenZipsComponent } from './components/pages/Blogs/july-top-ten-zips/july-top-ten-zips.component';
import { HardMoneyLoansNorthCarolinaPageComponent } from './components/pages/hard-money-loans-north-carolina-page/hard-money-loans-north-carolina-page.component';
import { HardMoneyLoansArizonaPageComponent } from './components/pages/hard-money-loans-arizona-page/hard-money-loans-arizona-page.component';
import { HardMoneyLoansFloridaPageComponent } from './components/pages/hard-money-loans-florida-page/hard-money-loans-florida-page.component';
import { HardMoneyLoansTexasPageComponent } from './components/pages/hard-money-loans-texas-page/hard-money-loans-texas-page.component';
import { HardMoneyLoansCaliforniaPageComponent } from './components/pages/hard-money-loans-california-page/hard-money-loans-california-page.component';
import { HardMoneyLoansTennesseePageComponent } from './components/pages/hard-money-loans-tennessee-page/hard-money-loans-tennessee-page.component';
import { HardMoneyLoansGeorgiaPageComponent } from './components/pages/hard-money-loans-georgia-page/hard-money-loans-georgia-page.component';
import { HardMoneyLoansSanDiegoPageComponent } from './components/pages/hard-money-loans-san-diego-page/hard-money-loans-san-diego-page.component';
import { HardMoneyLoansLosAngelesPageComponent } from './components/pages/hard-money-loans-los-angeles-page/hard-money-loans-los-angeles-page.component';
import { HardMoneyLoansNashvillePageComponent } from './components/pages/hard-money-loans-nashville-page/hard-money-loans-nashville-page.component';
import { QuestionSelfEmployedTo2021Component } from './components/pages/question-self-employed-to2021/question-self-employed-to2021.component';
import { TaxCreditQuestionCaredForChild2020to2021Component } from './components/pages/tax-credit-question-cared-for-child2020to2021/tax-credit-question-cared-for-child2020to2021.component';
import { TaxCreditQuestionPotentialTaxCreditComponent } from './components/pages/tax-credit-question-potential-tax-credit/tax-credit-question-potential-tax-credit.component';
import { TaxCreditQuestionApproxIncome2020to2021Component } from './components/pages/tax-credit-question-approx-income2020to2021/tax-credit-question-approx-income2020to2021.component';
import { TaxCreditQuestionLostAbilityToWork2020to2021Component } from './components/pages/tax-credit-question-lost-ability-to-work2020to2021/tax-credit-question-lost-ability-to-work2020to2021.component';
import { TaxCreditQuestionSelfEmployed2020to2021Component } from './components/pages/tax-credit-question-self-employed2020to2021/tax-credit-question-self-employed2020to2021.component';
import { TaxCreditQuestionPhoneNumberComponent } from './components/pages/tax-credit-question-phone-number/tax-credit-question-phone-number.component';
import { TaxCreditQuestionEmailAddressComponent } from './components/pages/tax-credit-question-email-address/tax-credit-question-email-address.component';
import { TaxCreditQuestionFirstAndLastNameComponent } from './components/pages/tax-credit-question-first-and-last-name/tax-credit-question-first-and-last-name.component';
import { TaggedTemplateExpr } from '@angular/compiler';
import { TaxCreditQuestionNotQualifiedComponent } from './components/pages/tax-credit-question-not-qualified/tax-credit-question-not-qualified.component';
import { HowItWorksPageComponent } from './components/pages/how-it-works-page/how-it-works-page.component';
import { TaxCreditQuestionLowIncomeComponent } from './components/pages/tax-credit-question-low-income/tax-credit-question-low-income.component';

const routes: Routes = [
    {path: '', component: TaxCreditQuestionSelfEmployed2020to2021Component },
    {
        path: 'contact', 
        component: ContactPageComponent,
        title: 'Nvestor Funding: Contact Us for Any Questions'
    },
    {   
        path: 'were-you-self-employed-2020-2021', 
        component: TaxCreditQuestionSelfEmployed2020to2021Component,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'did-you-care-for-a-child-2020-to-2021', 
        component: TaxCreditQuestionCaredForChild2020to2021Component,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'did-you-lost-ability-to-work-2020-to-2021', 
        component: TaxCreditQuestionLostAbilityToWork2020to2021Component,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'whats-your-approx-income-2020-to-2021', 
        component: TaxCreditQuestionApproxIncome2020to2021Component,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'your-potential-tax-credit', 
        component: TaxCreditQuestionPotentialTaxCreditComponent,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'lets-schedule-a-call', 
        component: TaxCreditQuestionLowIncomeComponent,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'your-phone-number', 
        component: TaxCreditQuestionPhoneNumberComponent,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'your-email-address', 
        component: TaxCreditQuestionEmailAddressComponent,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'your-name', 
        component: TaxCreditQuestionFirstAndLastNameComponent,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'we-have-news-for-you', 
        component: TaxCreditQuestionNotQualifiedComponent,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: 'how-it-works', 
        component: HowItWorksPageComponent,
        title: 'Get Access Up To 32,220 In SETC | The Tax Expert'
    },
    {   
        path: '**', 
        component: NotFoundComponent
    } // This line will remain down from the whole pages component list
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }