import { Component } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';

@Component({
  selector: 'app-tax-credit-question-low-income',
  templateUrl: './tax-credit-question-low-income.component.html',
  styleUrls: ['./tax-credit-question-low-income.component.scss']
})
export class TaxCreditQuestionLowIncomeComponent {
  constructor(public ghl: GoHighLevelService) { }
}
