import { Component, OnInit } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';

@Component({
  selector: 'app-tax-credit-question-not-qualified',
  templateUrl: './tax-credit-question-not-qualified.component.html',
  styleUrls: ['./tax-credit-question-not-qualified.component.scss']
})
export class TaxCreditQuestionNotQualifiedComponent {
  constructor(public ghl: GoHighLevelService) { }
}
