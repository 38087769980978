<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="app-video-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col">
                    <p class="youtube-p">Question 4 of 7</p>
                    <div class="app-intro-video-content">
                        <h1 class="headline">What was your <span>estimated annual income</span>, after taxes and
                            deductions, during <span>this period</span>?</h1>
                        <div class="row">
                            <div class="col">
                                <ngx-slider [(value)]="value" [options]="options" change="IncomeCheck(value)"></ngx-slider>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <h5 class="haus-answer-h5" *ngIf="this.ghl.ApproxIncome2020to2021">Estimated Income During This
                            Period: <span style="color: #0F50A1;">{{ this.ghl.ApproxIncome2020to2021 | currency
                                }}</span></h5>
                        <a class="haus-btn" *ngIf="this.ghl.ApproxIncome2020to2021 > 1"
                            (click)="this.ghl.DailyIncome(); this.ghl.DailyIncome67(); this.ghl.MaxAmount(); this.ghl.RouterLinkCheck();"
                            routerLink="/your-phone-number">
                            <h1>NEXT</h1>
                        </a>
                        <h5 class="haus-h5" *ngIf="this.ghl.ApproxIncome2020to2021"
                            routerLink="/did-you-lost-ability-to-work-2020-to-2021">Go Back</h5>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="video-popup" *ngIf="isOpen">
        <div class="popup-inner">
            <iframe src="../../../../assets/img/video/what-is-the-SETC.mp4" title="Tax Credit" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            <button (click)="closePopup();" type="button" class="close-btn">
                <i class="ri-close-line"></i>
            </button>
        </div>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>