<div class="app-screenshots-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">YOU MAY BE ELIGIBLE</span>
            <h2>Join the thousands who have successfully qualified for the SETC</h2>
        </div>
        <div class="app-screenshots-slides">
            <owl-carousel-o [options]="screenshotsSlides">
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/tax-credit-eligibility-1.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/tax-credit-eligibility-2.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/tax-credit-eligibility-3.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/tax-credit-eligibility-4.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-screenshot-card">
                        <img src="../../../../assets/img/just-funded/tax-credit-eligibility-5.png" alt="Nvestor Funding Just Funded">
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>