<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="app-video-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <p class="youtube-p">Question 7 of 7</p>
                    <div class="app-intro-video-content">
                        <h1 class="headline">So we properly address you, what's your <span>first and last name</span>?</h1>
                        <div class="row">
                            <form class="multiple-box"> 
                                <input                        
                                       class="form-control haus-form-control"
                                       type="text" 
                                       placeholder="First Name"
                                       [(ngModel)]="this.ghl.FirstName" 
                                       [ngModelOptions]="{standalone: true}" 
                                       maxlength="25" 
                                       required >
                                <input                        
                                       class="form-control haus-form-control"
                                       type="text" 
                                       placeholder="Last Name"
                                       [(ngModel)]="this.ghl.LastName" 
                                       [ngModelOptions]="{standalone: true}" 
                                       maxlength="25" 
                                       required >
                            </form>
                        </div>
                    </div>
                    <div class="row">
                        <h5 class="haus-answer-h5" *ngIf="this.ghl.LastName">Your Name: <span style="color: #00BF63;">{{ this.ghl.FirstName }} {{ this.ghl.LastName }}</span></h5>
                        <a class="haus-btn" *ngIf="this.ghl.LastName" (click)="this.ghl.pushToGhl();" routerLink="{{ this.ghl.link }}">
                            <h1>NEXT</h1>
                        </a>
                        <h5 class="haus-h5" *ngIf="this.ghl.LastName" routerLink="/your-phone-number">Go Back</h5>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="app-intro-video-box">
                        <img src="../../../../assets/img/video/tax-credit-advocates.png" alt="video-img">
                        <button class="video-btn popup-video" (click)="openPopup()"><i class="ri-play-line"></i></button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="video-popup" *ngIf="isOpen">
        <div class="popup-inner">
            <iframe src="../../../../assets/img/video/whats-next.mp4" title="Tax Credit" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen></iframe>
            <button (click)="closePopup();" type="button" class="close-btn">
                <i class="ri-close-line"></i>
            </button>
        </div>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>