<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="app-video-area pb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="app-intro-video-content">
                        <h1 class="headline">Were you self-employed, a sole proprietor, a 1099 independent contractor, and/or a
                            freelancer in 2020 and/or 2021?
                        </h1>
                        <div class="row">
                            <div class="col">
                                <a (click)="this.SelfEmployedCheck('Yes');">
                                    <div class="application-questions-box">
                                        <p>{{ this.Check[0].check }}</p>
                                    </div>
                                </a>
                                <a (click)="this.SelfEmployedCheck('No');">
                                    <div class="application-questions-box">
                                        <p>{{ this.Check[1].check }}</p>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-lg-6 col-md-12">
                    <div class="app-intro-video-box">
                        <img src="../../../../assets/img/video/tax-credit-advocates.png" alt="video-img">
                        <button class="video-btn popup-video" (click)="openPopup()"><i class="ri-play-line"></i></button>
                    </div>
                </div> -->
                <h5 class="haus-answer-h5" *ngIf="this.ghl.SelfEmployed2020to2021">Self Employed 2020 to 2021:
                    <span style="color: #0F50A1;">{{ this.ghl.SelfEmployed2020to2021 }}</span>
                </h5>
                <a class="haus-btn" *ngIf="this.ghl.SelfEmployed2020to2021" routerLink="/whats-your-email-address">
                    <h1>Next</h1>
                </a>
                <h5 class="haus-h5" *ngIf="this.ghl.SelfEmployed2020to2021" routerLink="/owned-long-term-rentals">Go Back</h5>
            </div>
        </div>
    </div>
    
    <div class="video-popup" *ngIf="isOpen">
        <div class="popup-inner">
            <iframe src="../../../../assets/img/video/getting-started.mp4" title="Nvestor Funding Steve Afra" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            <button (click)="closePopup()" type="button" class="close-btn">
                <i class="ri-close-line"></i>
            </button>
        </div>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>