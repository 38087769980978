import { Component, OnInit } from '@angular/core';
import { GoHighLevelService } from 'src/app/Services/go-high-level.service';

@Component({
  selector: 'app-question-self-employed-to2021',
  templateUrl: './question-self-employed-to2021.component.html',
  styleUrls: ['./question-self-employed-to2021.component.scss']
})
export class QuestionSelfEmployedTo2021Component {
  public Check:any = [
    {
      check: 'Yes',
      value: 'Yes'
    },
    {
      check: 'No',
      value: 'No'
    }
  ];

  constructor(public ghl:GoHighLevelService) { }

  ngOnInit(): void {
  }

  public SelfEmployedCheck(value :any) {
    this.ghl.SelfEmployed2020to2021 = value;
    console.log('Self Employed 2020 to 2021:', this.ghl.SelfEmployed2020to2021);
  }

  // Video Popup
  isOpen = false;
  openPopup(): void {
      this.isOpen = true;
  }
  closePopup(): void {
      this.isOpen = false;
  }

}
