<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 200px;" class="ptb-100">
    <div class="container headline questions-width">
        <img style="max-width: 10%;" src="../../../../assets/img/logo/money-logo.png" />
        <h5 *ngIf="this.ghl.ApproxIncome2020to2021">Well this is good news, {{ this.ghl.FirstName }}!</h5>
        <h5 style="margin-top: 10px;">Based on your answers, you could be eligible for a tax credit up to</h5>
        <h1 class="potential-tax-credit">{{ this.ghl.MaxRefundAmount | currency }}</h1>
        <p>This is just an estimate. To get more precise calculations and file for your refund, click below to start
            your application now OR schedule a time to walk through it one-on-one with an SETC specialist.
        </p>
        <a style="margin: 10px;" href="https://api.leadconnectorhq.com/widget/booking/wApdCjTlCXOn3JmNpdxC"
            target="_blank" class="btn">
            <h1>SCHEDULE CALL</h1>
        </a>
        <a style="margin: 10px;" href="https://www.setctaxreturn.com/pre-qualification/?r=applynow&source_id=landingpage" target="_blank"
            class="btn">
            <h1>GET MY CREDIT</h1>
        </a>
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>