<app-navbar-style-two></app-navbar-style-two>
<div style="margin-top: 100px;" class="ptb-100">
    <div class="container headline questions-width">
        <h1 class="potential-tax-credit">Well..</h1>
        <h1 class="headline">Based on <span>not being self-employed</span> in 2020 and/or 2021, <span>we won't</span> we
            able to assist you at this moment with the <span>Self-employed Tax Credit</span>.</h1>
        <img src="../../../../assets/img/banner/sorry.png" />
    </div>
</div>
<app-main-just-funded></app-main-just-funded>
<app-footer-style-five></app-footer-style-five>