<div
    class="navbar-area navbar-style-two"
    [ngClass]="{'sticky': isSticky}"
>
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img style="width: 30%;" src="../../../../assets/img/logo/the-tax-credit-expert-logo-dark.png" alt="Tax Credit Advocates Logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <ul class="navbar-nav">
   
                </ul>
                <div class="others-option">
                    <a href="tel:16026012340" class="default-btn">Contact Us: (602) 601-2340</a>
                </div>
            </div>
        </nav>
    </div>
</div>